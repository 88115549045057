<template>
  <div class="myNewsDetails MYNewsDetails">
    <!-- 新闻详情 -->
    <van-nav-bar title="新闻详情"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <div class="title">
        <div class="name">{{news.newstitle}}</div>
        <div class="desc">
          <span>发布时间：{{news.crdate}}</span>
          <span>文字来源：{{news.crman}}</span>
          <span>点击量：{{news.readcount}}</span>
        </div>
      </div>
      <div ref="newHtml" class="newHtml"
           v-html="news.newsmemo"></div>
    </div>
  </div>
</template>

<script>
import { getNewsInfo } from '@api/wx.js'
import { ImagePreview } from 'vant';
export default {
  data () {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      autoid: this.$route.query.autoid,
      from: this.$route.query.from,
      news: {},
    }
  },
  created () {
    this.getNewsDetails()
  },
  methods: {
    onClickLeft () {
      let fr = this.from || this.$route.query.from
      if (fr == 'home')
      {
        this.$router.push('/home')
      } else
      {
        this.$router.push('/newsCenter')
      }
    },
    getNewsDetails () {
      getNewsInfo({
        autoid: this.autoid,
        username: this.userInfo.username
      }).then(res => {
        console.log(res, 'resresres');
        this.news = res.data[0];
        let dom = this.$refs.newHtml;
        this.$nextTick(()=>{
          dom.addEventListener("click",(e)=>{
            let str = e.srcElement.toString()
            if(str.indexOf("HTMLImageElement") != -1){
              let src = e.srcElement.currentSrc;
              ImagePreview([src]);
            }
            
          })
        })
      })
    }

  }
}
</script>

<style lang="less" scoped>
.myNewsDetails {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    .title {
      padding: 20px;
      .name {
        padding: 30px 0;
        font-size: 36px;
        font-weight: 500;
        text-align: center;
      }
      .desc {
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        color: #999999;
      }
    }
    .newHtml {
      padding: 30px;
      p {
        line-height: 1.5 !important;
      }
    }
  }
}
</style>